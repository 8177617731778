import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image';
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby';
import { fluidImage400, fluidImageWide } from '../fragments';
import Helmet from 'react-helmet'
const Projects = () => (
  <StaticQuery
    query={graphql`
          query ProjectQuery {
            hubqlImg: file(relativePath: { eq: "hubql-logo.png" }) {
              ...fluidImage400
            }
            briklImg: file(relativePath: { eq: "brikl.jpg" }) {
              ...fluidImage400
            }
            designedImg: file(relativePath: { eq: "designed-logo.png" }) {
              ...fluidImage400
            }
            designedImg: file(relativePath: { eq: "designed-logo.png" }) {
              ...fluidImage400
            }
            graphQLBangkok: file(relativePath: { eq: "graphql.png" }) {
              ...fluidImage400
            }
            graphQLAsia: file( relativePath: { eq: "GraphQL-Asia-Logo.png"} ){
              ...fluidImage400
            }
            # nathan-dumlao-1096176-unsplash.jpg
            # tobias-meixner-blue-mountain.jpg
            stepImg: file( relativePath: { eq: "nathan-dumlao-1096176-unsplash.jpg"} ){
              ...fluidImageWide
            }
          }
        `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Tobias Meixner - Projects</title>
        </Helmet>
        <Img
          fluid={data.stepImg.childImageSharp.fluid}
          style={{
            // display: 'inline',
            // width: '50%',
            height: '235px',
            margin: 0,
            // objectFit: 'cover'
          }} alt="Stone steps" />
        <div className={"quote"}>
          If you can't explain it simply, you don't understand it well enough.
      <span>Albert Einstein</span>
        </div>
        <h1>Projects</h1>
        <ul className="projectList">
          {
            [
              {
                src: data.hubqlImg.childImageSharp.fluid,
                title: 'Hubql',
                position: 'Co-Founder',
                link: 'https://www.hubql.com'
              },
              {
                // src: data.graphQLBangkok.childImageSharp.fluid,
                title: 'Your project?',
                position: 'Contact me',
                link: '/contact'
              },
              {
                src: data.briklImg.childImageSharp.fluid,
                title: 'Brikl',
                position: 'Co-Founder & prev. CTO',
                link: 'https://www.brikl.com'
              },
              {
                src: data.designedImg.childImageSharp.fluid,
                title: 'Designed',
                position: 'Technical Advisor',
                link: 'https://designed.org/'
              },
              {
                src: data.graphQLBangkok.childImageSharp.fluid,
                title: 'GraphQL Bangkok Meetup',
                position: 'Organiser',
                link: 'https://www.meetup.com/GraphQL-Bangkok/'
              },
              {
                src: data.graphQLAsia.childImageSharp.fluid,
                title: 'GraphQL Asia Conference 2019',
                position: 'Co-Organiser',
                link: 'https://www.graphql-asia.org/'
              },             
            ].map((photo, index) => {
              return (
                <li key={index + 'img'}>
                  <a target="_blank" rel="noopener noreferrer" href={photo.link} >
                    {photo.src ? <Img fluid={photo.src} alt={photo.title} /> : <span style={{
                      height: 80,display:'block',width:80
                    }}></span>}
                    <strong>
                      {photo.title}
                    </strong>
                    <span>
                      {photo.position}
                    </span>
                  </a>
                </li>
              )
            })
          }
        </ul>

      </Layout>
    )} />
)

export default Projects
